import { isAfter } from "date-fns/isAfter";
import { addSeconds } from "date-fns/addSeconds";
import { getLocalStorageItem as _getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "../features/app/utils";
export default function cacheProvider() {
    function getLocalStorageItem(key) {
        return JSON.parse(_getLocalStorageItem(key));
    }
    return {
        has: function has(key) {
            var item = getLocalStorageItem(key);
            if (!item) {
                return false;
            }
            if (item.expiration > 0 && isAfter(new Date(), new Date(item.expiration))) {
                removeLocalStorageItem(key);
                return false;
            }
            return true;
        },
        get: function get(key) {
            if (!this.has(key)) {
                return null;
            }
            return getLocalStorageItem(key).value;
        },
        set: function set(key, value) {
            var seconds = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0;
            return setLocalStorageItem(key, JSON.stringify({
                value: value,
                expiration: seconds > 0 ? addSeconds(new Date(), seconds) : seconds
            }));
        },
        remove: function remove(key) {
            removeLocalStorageItem(key);
        }
    };
}
