export function isCompany(type) {
    return type === PARTNER_TYPES.COMPANY;
}
export function isPerson(type) {
    return type === PARTNER_TYPES.PERSON;
}
export function getRolesOfPartnerFromFlags(isSupplier, isClient) {
    if (isSupplier && isClient) {
        return [
            PARTNER_ROLES.SUPPLIER,
            PARTNER_ROLES.CLIENT
        ];
    } else if (isClient) {
        return [
            PARTNER_ROLES.CLIENT
        ];
    } else if (isSupplier) {
        return [
            PARTNER_ROLES.SUPPLIER
        ];
    } else {
        return [];
    }
}
export var PARTNER_ROLES = {
    SUPPLIER_CLIENT: "client-and-supplier",
    CLIENT: "client",
    SUPPLIER: "supplier",
    INACTIVE: "inactive"
};
export var PARTNER_TYPES = {
    COMPANY: "company",
    PERSON: "person"
};
export function convertRoleToArray(roles) {
    switch(roles){
        case PARTNER_ROLES.SUPPLIER_CLIENT:
            return [
                PARTNER_ROLES.SUPPLIER,
                PARTNER_ROLES.CLIENT
            ];
        case PARTNER_ROLES.CLIENT:
            return [
                PARTNER_ROLES.CLIENT
            ];
        case PARTNER_ROLES.SUPPLIER:
            return [
                PARTNER_ROLES.SUPPLIER
            ];
        default:
            return [];
    }
}
//! Used to map between filters the api wants (or we just want in url) AND the properties of the partner the selector wants
export var partnerSelectorAccessorArray = [
    {
        name: "partnerId",
        accessor: "id"
    },
    {
        name: "partnerName",
        accessor: "name"
    }
];
export var deliveryPartnerSelectorAccessorArray = [
    {
        name: "deliveryPartnerId",
        accessor: "id"
    },
    {
        name: "deliveryPartnerName",
        accessor: "name"
    }
];
