function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { createReducer } from "@reduxjs/toolkit";
import { memoize } from "proxy-memoize";
/*******************
 * ACTION TYPES
 *******************/ var actionTypes = {
    SET_CURRENT_LISTING_LOADER: "listingFactory/SET_CURRENT_LISTING_LOADER",
    SET_INITIAL_LOADED_DATA: "listingFactory/SET_INITIAL_LOADED_DATA",
    CLEAR_INITIAL_LOADED_DATA: "listingFactory/CLEAR_INITIAL_LOADED_DATA",
    SET_CURRENT_LISTING_STATE: "listingFactory/SET_CURRENT_LISTING_STATE",
    RESET_CURRENT_LISTING_STATE: "listingFactory/RESET_CURRENT_LISTING_STATE"
};
/*******************
 * REDUCER
 *******************/ export default createReducer({
    currentListingLoader: null,
    initialLoadedData: null,
    prev: null
}, function(builder) {
    builder.addCase(actionTypes.SET_CURRENT_LISTING_LOADER, function(state, action) {
        return _object_spread_props(_object_spread({}, state), {
            currentListingLoader: action.payload
        });
    });
    builder.addCase(actionTypes.SET_INITIAL_LOADED_DATA, function(state, action) {
        return _object_spread_props(_object_spread({}, state), {
            initialLoadedData: action.payload
        });
    });
    builder.addCase(actionTypes.CLEAR_INITIAL_LOADED_DATA, function(state) {
        return _object_spread_props(_object_spread({}, state), {
            initialLoadedData: null
        });
    });
    builder.addCase(actionTypes.SET_CURRENT_LISTING_STATE, function(state, action) {
        return _object_spread_props(_object_spread({}, state), {
            prev: _object_spread({}, action.payload)
        });
    //TODO 999 -> find if something was expecting the empty object here, if not, replace the line above with the one below
    // return { ...state, prev: action.payload ? { ...action.payload } : null };
    });
    builder.addCase(actionTypes.RESET_CURRENT_LISTING_STATE, function() {
        return {
            currentListingLoader: null,
            initialLoadedData: null,
            prev: null
        };
    });
});
/*******************
 * ACTIONS
 *******************/ export var listFactoryActions = {
    setCurrentListingLoader: function setCurrentListingLoader(listingLoader) {
        return {
            type: actionTypes.SET_CURRENT_LISTING_LOADER,
            payload: listingLoader
        };
    },
    setInitialLoadedData: function setInitialLoadedData(data) {
        return {
            type: actionTypes.SET_INITIAL_LOADED_DATA,
            payload: data
        };
    },
    clearInitialLoadedData: function clearInitialLoadedData() {
        return {
            type: actionTypes.CLEAR_INITIAL_LOADED_DATA
        };
    },
    setCurrentListingState: function setCurrentListingState(listingState) {
        return {
            type: actionTypes.SET_CURRENT_LISTING_STATE,
            payload: listingState
        };
    },
    resetCurrentListingState: function resetCurrentListingState() {
        return {
            type: actionTypes.RESET_CURRENT_LISTING_STATE
        };
    }
};
/*******************
 * SELECTORS
 *******************/ export var listFactorySelectors = {
    getCurrentListingLoader: memoize(function(state) {
        return state.listFactory.currentListingLoader;
    }),
    getInitialLoadedData: memoize(function(state) {
        return state.listFactory.initialLoadedData;
    }),
    getCurrentListingState: memoize(function(state) {
        return state.listFactory.prev;
    })
};
