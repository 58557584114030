function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { object, string, mixed, number } from "yup";
import { transliterate } from "transliteration";
export var DEFAULT_COUNTRY_CODE = "RO";
export function isCityBucuresti(city) {
    return transliterate(city === null || city === void 0 ? void 0 : city.toLowerCase()) === "bucuresti";
}
export function isDefaultCountry(countryCode) {
    return countryCode === DEFAULT_COUNTRY_CODE;
}
export var addressValidator = object().shape({
    countryCode: string().required("required"),
    countyCode: mixed().when([
        "city",
        "countryCode"
    ], {
        is: function(city, countryCode) {
            return !isCityBucuresti(city) && isDefaultCountry(countryCode);
        },
        then: function() {
            return string().required("required");
        },
        otherwise: function() {
            return string().max(55, "max:55");
        }
    }),
    city: string().required("required").max(50, "max:50"),
    district: mixed().when("city", {
        is: function(city) {
            return isCityBucuresti(city);
        },
        then: function() {
            return number().required("required");
        }
    }),
    zipCode: string().max(20, "max:20"),
    street: string().required("required").max(130, "max:150"),
    additionalStreet: string().max(100, "max:100"),
    additionalAddress: string().max(100, "max:100")
});
/**
 * Used when running the company ID generator
 */ export function setAddressDetails(newValues) {
    var fromIntraCommunityVatNumberRO = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false, oldValues = arguments.length > 2 ? arguments[2] : void 0;
    return fromIntraCommunityVatNumberRO ? _object_spread_props(_object_spread({}, oldValues), {
        additionalAddress: newValues.address.additionalAddress || "",
        additionalStreet: newValues.address.additionalStreet || "",
        countryCode: newValues.address.countryCode || ""
    }) : {
        countryCode: newValues.address.countryCode || "",
        countyCode: newValues.address.countyCode || "",
        city: newValues.address.city || "",
        district: newValues.address.district || "",
        street: newValues.address.street || "",
        additionalAddress: newValues.address.additionalAddress || "",
        additionalStreet: newValues.address.additionalStreet || "",
        zipCode: newValues.address.zipCode || ""
    };
}
export function preSubmitAddress(values) {
    var shownDistrict = isCityBucuresti(values.city);
    /**
     * If the district should be shown, put it in the req., otherwise remove it from the req.
     */ var district = values.district, countyCode = values.countyCode, newAddress = _object_without_properties(values, [
        "district",
        "countyCode"
    ]);
    if (shownDistrict) {
        newAddress.district = district;
    }
    if (!shownDistrict) {
        newAddress.countyCode = countyCode;
    }
    return newAddress;
}
