function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
var defaultTheme = require("tailwindcss/defaultTheme");
/** @type {import('tailwindcss').Config} */ module.exports = {
    darkMode: "class",
    content: [
        "./app/**/*.{js,jsx}",
        "node_modules/preline/dist/*.js"
    ],
    plugins: [
        require("@tailwindcss/forms"),
        require("@tailwindcss/typography"),
        require("@tailwindcss/aspect-ratio")
    ],
    theme: {
        extend: {
            colors: {
                black: "#141C35",
                "red-450": "#EB5757",
                "gray-100": "#F9F9FC",
                "gray-150": "#F2F3F9",
                "gray-250": "#DEDEE4",
                "gray-625": "#78789B",
                "gray-650": "#474868",
                "gray-675": "#3b3c56",
                "gray-750": "#293647",
                "green-50": "#F0FAF7",
                "green-500": "#4EB792",
                "green-700": "#0E885D",
                "blue-50": "#F2F3F9",
                "blue-100": "#E5EAFE",
                "blue-200": "#DBEAFE",
                "blue-300": "#9cb2ff",
                "blue-550": "#4F72EE",
                "blue-750": "#46476C",
                "blue-760": "#323356",
                "blue-770": "#2d2e4f",
                "blue-775": "#2a2c4c",
                "blue-800": "#282945",
                "blue-850": "#252744",
                "blue-900": "#21223E",
                "brand-yellow-500": "#ffd03d"
            },
            fontSize: {
                "3xl": "2rem"
            },
            fontFamily: {
                sans: [
                    "Inter"
                ].concat(_to_consumable_array(defaultTheme.fontFamily.sans))
            },
            boxShadow: {
                around: "0 0 10px rgba(0,0,0,0.3)"
            },
            screens: {
                "3xl": "1800px",
                "4xl": "2000px"
            },
            keyframes: {
                toasterAnimateIn: {
                    from: {
                        transform: "translateX(150%)"
                    },
                    to: {
                        transform: "translateX(0)"
                    }
                },
                toasterAnimateOut: {
                    from: {
                        opacity: 1
                    },
                    to: {
                        opacity: 0
                    }
                },
                toasterSwipeOut: {
                    from: {
                        transform: "translateX(var(--radix-toast-swipe-end-x))"
                    },
                    to: {
                        transform: "translateX(150%)"
                    }
                },
                openSidebarSubmenu: {
                    from: {
                        height: 0
                    },
                    to: {
                        height: "var(--radix-accordion-content-height)"
                    }
                },
                closeSidebarSubmenu: {
                    from: {
                        height: "var(--radix-accordion-content-height)"
                    },
                    to: {
                        height: 0
                    }
                },
                fadeIn: {
                    from: {
                        opacity: 0,
                        transform: "translateY(-1rem)"
                    },
                    to: {
                        opacity: 100,
                        transform: "translateY(0px)"
                    }
                }
            },
            animation: {
                toasterIn: "toasterAnimateIn var(--animationDuration) cubic-bezier(0.16, 1, 0.3, 1)",
                toasterOut: "toasterAnimateOut var(--animationDuration) ease-in",
                toasterSwipeOut: "toasterSwipeOut var(--animationDuration) ease-out",
                openSidebarSubmenu: "openSidebarSubmenu 200ms ease-out",
                closeSidebarSubmenu: "closeSidebarSubmenu 200ms ease-out",
                fadeIn: "fadeIn 200ms ease-in"
            },
            translate: {
                toasterMove: "var(--radix-toast-swipe-move-x)"
            }
        }
    }
};
