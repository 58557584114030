function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { useEffect, useState } from "react";
import posthog from "posthog-js";
import { useSelector } from "react-redux";
import { configSelectors } from "../../redux/config";
import handleReportError from "../errors/handleReportError";
export default function CookiebotManager() {
    var _useState = _sliced_to_array(useState(false), 2), consents = _useState[0], setConsents = _useState[1];
    var posthogAppId = useSelector(configSelectors.posthogAppId);
    useEffect(function() {
        try {
            window.addEventListener("CookiebotOnConsentReady", function() {
                return setConsents(window.Cookiebot.consent);
            }, false);
            window.addEventListener("CookiebotOnAccept", function() {
                return setConsents(window.Cookiebot.consent);
            }, false);
            window.addEventListener("CookiebotOnDecline", function() {
                return setConsents(window.Cookiebot.consent);
            }, false);
        } catch (e) {
            console.error(e);
            handleReportError(e);
        }
    }, []);
    useEffect(function() {
        if (!consents) {
            return;
        }
        console.info("COOKIEBOT CONSENTS", consents);
        // First time
        if (!posthog.__loaded) {
            if (consents === null || consents === void 0 ? void 0 : consents.statistics) {
                console.debug("FIRST TIME - ACCEPTED & INIT COOKIE");
                posthog.init(posthogAppId, {
                    api_host: "https://eu.i.posthog.com",
                    persistence: "cookie",
                    person_profiles: "always",
                    capture_pageview: false
                });
                posthog.capture("$pageview", {
                    $current_url: window.location.href
                });
            } else {
                console.debug("FIRST TIME - NOT ACCEPTED - INIT TO MEMORY");
                posthog.init(posthogAppId, {
                    api_host: "https://eu.i.posthog.com",
                    persistence: "memory",
                    person_profiles: "always",
                    capture_pageview: false,
                    opt_out_capturing_by_default: true
                });
                posthog.capture("$pageview", {
                    $current_url: window.location.href
                });
            }
        } else {
            // User changed consent
            if (!(consents === null || consents === void 0 ? void 0 : consents.statistics)) {
                console.debug("OPT OUT");
                posthog.opt_out_capturing();
                posthog.set_config({
                    persistence: "memory"
                });
            } else {
                console.debug("OPT IN");
                posthog.set_config({
                    persistence: "cookie"
                });
                posthog.identify();
                posthog.opt_in_capturing();
                posthog.capture("$pageview", {
                    $current_url: window.location.href
                });
            }
        }
    }, [
        consents
    ]);
    return null;
}
