import { useLocation, useNavigate as _useNavigate } from "react-router-dom";
import { stringify } from "qs";
import useGeneratePath from "./generatePath";
export default function useNavigate() {
    var location = useLocation();
    var _navigate = _useNavigate();
    var generatePath = useGeneratePath();
    return function navigate(to) {
        var params = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, query = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, allowNavigateToSameUrl = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false, state = arguments.length > 4 ? arguments[4] : void 0, replace = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : false;
        var search = stringify(query);
        var pathname = generatePath(to, params); // we don't use query here since _navigate needs the search separately
        if (!allowNavigateToSameUrl && location.pathname === pathname && location.search === search) {
            return;
        }
        return _navigate({
            pathname: pathname,
            search: search
        }, {
            replace: replace,
            state: state
        });
    };
}
