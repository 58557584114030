import { format, parse } from "date-fns";
import { DEFAULT_DATE_DISPLAY, DEFAULT_DATE_VALUE_WITH_TIME } from "../app/utils";
export var DEFAULT_NOTIFICATIONS_PER_PAGE = 4;
export var DEFAULT_NOTIFICATIONS_SORT_BY = "createdAt";
export var NOTIFICATIONS_FILTER_IS_READ = "isRead";
export function groupNotifications(notificationsList, dateFnsLocale) {
    var groupedNotifications = {};
    notificationsList.forEach(function(notification) {
        var createdAt = notification.createdAt;
        var formattedDate = format(parse(createdAt || null, DEFAULT_DATE_VALUE_WITH_TIME, new Date()), DEFAULT_DATE_DISPLAY, {
            locale: dateFnsLocale
        });
        if (!groupedNotifications[formattedDate]) {
            groupedNotifications[formattedDate] = [];
        }
        groupedNotifications[formattedDate].push(notification);
    });
    return groupedNotifications;
}
