function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { createReducer } from "@reduxjs/toolkit";
import { memoize } from "proxy-memoize";
/*******************
 * ACTION TYPES
 *******************/ var actionTypes = {
    RESET_CURRENT_PAGE_NEW_DATA: "currentPage/RESET_CURRENT_PAGE_NEW_DATA",
    UPDATE_STATE: "currentPage/UPDATE_STATE"
};
/*******************
 * REDUCER
 *******************/ export default createReducer({}, function(builder) {
    builder.addCase(actionTypes.UPDATE_STATE, function(state, action) {
        return _object_spread({}, state, action.payload);
    });
    builder.addCase(actionTypes.RESET_CURRENT_PAGE_NEW_DATA, function(state, action) {
        return _object_spread_props(_object_spread({}, state), _define_property({}, action.payload, null));
    });
});
/*******************
 * ACTIONS
 *******************/ export var currentPageActions = {
    updateState: function updateState(data) {
        return {
            type: actionTypes.UPDATE_STATE,
            payload: data
        };
    },
    resetCurrentPageNewData: function resetCurrentPageNewData(selectorName) {
        return {
            type: actionTypes.RESET_CURRENT_PAGE_NEW_DATA,
            payload: selectorName
        };
    }
};
/*******************
 * SELECTORS
 *******************/ var baseState = memoize(function(state) {
    return state.currentPage;
});
export var currentPageSelectors = {
    getCurrentPageData: memoize(function(state) {
        return baseState(state);
    })
};
