function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { generatePath as _generatePath, useParams } from "react-router-dom";
import { stringify } from "qs";
export default function useGeneratePath() {
    var currentParams = useParams();
    return function innerGeneratePath(to, params) {
        var query = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
        var search = stringify(query);
        var newParams = _object_spread({}, currentParams, params);
        to = to + (search ? "?".concat(search) : "");
        return _generatePath(to, newParams);
    };
}
