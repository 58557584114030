function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
import { captureException } from "@sentry/react";
import { CanceledError } from "axios";
export default function handleReportError(error) {
    console.error(error);
    /**
     * When this happens, it means that the app has new chunks. Just reload everything.
     */ if (/ChunkLoadError/.test(error.name) || /SyntaxError/.test(error.name)) {
        window.location.reload();
        return;
    }
    /**
     * Various scrappers run with jsdom and..this error happens
     */ if (error.message.includes("postMessage is not a function")) {
        return;
    }
    if (_instanceof(error, CanceledError) || error.response && [
        401,
        426,
        403,
        503
    ].includes(error.response.status)) {
        return;
    }
    captureException(error);
}
