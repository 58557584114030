import { configureStore } from "@reduxjs/toolkit";
import cacheProvider from "./cacheProvider";
import httpClientProvider from "./httpClientProvider";
import configReducer, { configActions } from "./../redux/config";
import authReducer from "./../redux/auth";
import myCompaniesReducer from "./../redux/myCompanies";
import listFactoryReducer from "../redux/listFactory";
import screenBreakpointsReducer from "../redux/screenBreakpoints";
import currentPageReducer from "../redux/currentPage.js";
import servicesReducer from "../redux/services.js";
import notificationsReducer from "../redux/notifications";
import btCodesReducer from "../redux/btCodes";
import sentryProvider from "./sentryProvider";
// We need to export the store instance since, otherwise, hot reload will recreate the store on each reload (if we export a function)
export var thunkOptions = {};
/**
 * ------------
 * Boot store
 * ------------
 */ var reduxStore = configureStore({
    reducer: {
        services: servicesReducer,
        config: configReducer,
        auth: authReducer,
        myCompanies: myCompaniesReducer,
        listFactory: listFactoryReducer,
        screenBreakpoints: screenBreakpointsReducer,
        currentPage: currentPageReducer,
        notifications: notificationsReducer,
        btCodes: btCodesReducer
    },
    middleware: function(getDefaultMiddleware) {
        return getDefaultMiddleware({
            thunk: {
                extraArgument: thunkOptions
            }
        });
    }
});
reduxStore.dispatch(configActions.loadConfig(process.config));
/**
 * ------------
 * Boot cache & httpClient
 * ------------
 */ thunkOptions.cache = cacheProvider();
thunkOptions.httpClient = httpClientProvider(reduxStore);
sentryProvider(reduxStore, thunkOptions);
export default reduxStore;
