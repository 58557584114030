function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { createReducer } from "@reduxjs/toolkit";
import { memoize } from "proxy-memoize";
/*******************
 * ACTION TYPES
 *******************/ var actionTypes = {
    STORE_GLOBAL_SERVICE: "services/STORE_GLOBAL_SERVICE"
};
/*******************
 * REDUCER
 *******************/ export default createReducer({}, function(builder) {
    builder.addCase(actionTypes.STORE_GLOBAL_SERVICE, function(state, action) {
        return _object_spread({}, state, action.payload);
    });
});
/*******************
 * ACTIONS
 *******************/ export var servicesActions = {
    storeGlobalService: function storeGlobalService(data) {
        return {
            type: actionTypes.STORE_GLOBAL_SERVICE,
            payload: data
        };
    }
};
/*******************
 * SELECTORS
 *******************/ var baseState = memoize(function(state) {
    return state.services;
});
export var servicesSelectors = {
    getServices: memoize(function(state) {
        return baseState(state);
    })
};
