function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { configSelectors } from "../redux/config";
export default function sentryProvider(reduxStore) {
    //, thunkOptions
    /**
     * ------------
     * Boot Sentry
     * ------------
     */ if (configSelectors.sentryEnabled(reduxStore.getState())) {
        // thunkOptions.sentryFeedback = new Sentry.feedbackIntegration({
        //     colorScheme: "system",
        //     showBranding: false,
        //     autoInject: false,
        // });
        Sentry.init({
            dsn: configSelectors.sentryDsn(reduxStore.getState()),
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    networkDetailAllowUrls: configSelectors.debug(reduxStore.getState()) ? [
                        configSelectors.apiHost(reduxStore.getState())
                    ] : [],
                    // networkResponseHeaders: configSelectors.debug(reduxStore.getState()),
                    maskAllText: !configSelectors.debug(reduxStore.getState()),
                    maskAllInputs: !configSelectors.debug(reduxStore.getState()),
                    blockAllMedia: !configSelectors.debug(reduxStore.getState())
                }),
                posthog.sentryIntegration({
                    organization: "middleout",
                    projectId: "4504718725939200",
                    severityAllowList: [
                        "error",
                        "info"
                    ]
                })
            ],
            environment: configSelectors.env(reduxStore.getState()),
            release: configSelectors.version(reduxStore.getState()),
            // Session Replay
            replaysSessionSampleRate: configSelectors.sentryReplaysSessionSampleRate(reduxStore.getState()),
            replaysOnErrorSampleRate: 1.0,
            // Capture % of the transactions, reduce in production!
            tracesSampleRate: configSelectors.sentryTracesSampleRate(reduxStore.getState()),
            normalizeDepth: 11,
            enabled: true,
            beforeSend: function beforeSend(event, hint) {
                var error = hint.originalException;
                // Do not send errors that have chunk load error or syntax error with specific message
                if (error && (/ChunkLoadError/.test(error.name) || /SyntaxError/.test(error.name))) {
                    return;
                }
                if (error && error.isAxiosError) {
                    if (error.response && error.response.data) {
                        var contexts = _object_spread({}, event.contexts);
                        contexts.errorResponse = {
                            data: error.response.data
                        };
                        event.contexts = contexts;
                    }
                }
                return event;
            }
        });
    }
}
