function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { memoize } from "proxy-memoize";
import { createReducer } from "@reduxjs/toolkit";
var defaultTheme = require("tailwindcss/defaultTheme");
var theme = require("../../tailwind.config.js").theme;
//* Getting default and extended(from config) screen sizes
var tailwindScreens = _object_spread({}, defaultTheme.screens, theme.extend.screens);
function getBreakpoint(width, breakpoints) {
    var breakpointKeys = Object.keys(breakpoints).reverse();
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = breakpointKeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var key = _step.value;
            if (width >= parseInt(breakpoints[key])) {
                return key;
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return "xs";
//less than any breakpoint
}
var actionTypes = {
    UPDATE_SCREEN_WIDTH: "screen/UPDATE_SCREEN_WIDTH"
};
export var screenBreakpointsActions = {
    updateScreenWidth: function updateScreenWidth(screenWidth) {
        return {
            type: actionTypes.UPDATE_SCREEN_WIDTH,
            payload: screenWidth
        };
    }
};
/*******************
 * REDUCER
 *******************/ export default createReducer({
    screenWidth: null
}, function(builder) {
    builder.addCase(actionTypes.UPDATE_SCREEN_WIDTH, function(state, action) {
        state.screenWidth = action.payload;
    });
});
var _isOver = memoize(function(param) {
    var state = param.state, breakpoint = param.breakpoint;
    return state.screenBreakpoints.screenWidth >= tailwindScreens[breakpoint].replace("px", "");
});
export var screenBreakpointsSelectors = {
    screenWidth: memoize(function(state) {
        return state.screenBreakpoints.screenWidth;
    }),
    breakPoint: memoize(function(state) {
        return getBreakpoint(state.screenBreakpoints.screenWidth, tailwindScreens);
    }),
    isOver: function(breakpoint) {
        return function(state) {
            return _isOver({
                state: state,
                breakpoint: breakpoint
            });
        };
    }
};
