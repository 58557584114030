import Big from "big.js";
import numbro from "numbro";
import { useNumericFormat } from "react-number-format";
import { getDecimalSeparator, getThousandsSeparator } from "./utils";
import { captureException } from "@sentry/react";
export default function useNumericValue(param) {
    var _param_nbDecimals = param.nbDecimals, nbDecimals = _param_nbDecimals === void 0 ? 2 : _param_nbDecimals, _param_trimDecimals = param.trimDecimals, trimDecimals = _param_trimDecimals === void 0 ? true : _param_trimDecimals, _param_prefix = param.prefix, prefix = _param_prefix === void 0 ? "" : _param_prefix, _param_suffix = param.suffix, suffix = _param_suffix === void 0 ? "" : _param_suffix, _param_allowLeadingZero = param.allowLeadingZero, allowLeadingZero = _param_allowLeadingZero === void 0 ? false : _param_allowLeadingZero, _param_showThousandsSeparator = param.showThousandsSeparator, showThousandsSeparator = _param_showThousandsSeparator === void 0 ? true : _param_showThousandsSeparator;
    var decimalsSeparator = getDecimalSeparator(numbro.language());
    var thousandsSeparator = showThousandsSeparator ? getThousandsSeparator(numbro.language()) : undefined;
    var formatter = useNumericFormat({
        displayType: "text",
        thousandsGroupStyle: showThousandsSeparator ? "thousand" : "none",
        allowLeadingZeros: allowLeadingZero,
        thousandSeparator: thousandsSeparator,
        decimalSeparator: decimalsSeparator,
        decimalScale: nbDecimals,
        fixedDecimalScale: !trimDecimals,
        valueIsNumericString: true,
        prefix: prefix,
        suffix: suffix
    });
    return function(value) {
        if (value === undefined) {
            captureException("NumberInput: Encountered an undefined value");
            return "";
        }
        if (isNaN(parseFloat(value))) {
            return "";
        }
        if (typeof value !== "string") {
            value = value.toString();
        } else {
            value = parseFloat(value);
            value = value.toString();
        }
        // If 1.12345.toFixed(4) !== 1.1234 then round 1.12345 to 4 decimals -> 1.1235
        var bigValue = Big(value);
        if (!bigValue.eq(Big(value).toFixed(nbDecimals))) {
            bigValue = Big(value).toFixed(nbDecimals);
        } else {
            // We can't use minNbDecimals and maxNbDecimals because the library will put the number of decimals
            // it will receive in decimalScale
            bigValue = bigValue.toFixed();
        }
        return formatter.format(bigValue);
    };
}
