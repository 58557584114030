import { Crisp } from "crisp-sdk-web";
import { useEffect } from "react";
import { configSelectors } from "../../redux/config";
import { useSelector } from "react-redux";
import { intlSelectors } from "../../redux/intl";
import { authSelectors } from "../../redux/auth";
export default function CrispChat() {
    var crispWebsiteId = useSelector(configSelectors.crispWebsiteId);
    var locale = useSelector(intlSelectors.getAppLocale);
    var hasIdentity = useSelector(authSelectors.hasIdentity);
    var isInjected = Crisp.isCrispInjected();
    useEffect(function() {
        if (!crispWebsiteId) {
            return;
        }
        Crisp.configure(crispWebsiteId, {
            autoload: false,
            locale: locale,
            safeMode: true
        });
        Crisp.session.onLoaded(function() {
            // Should always be behind the modal,modalOld and sidebar - backdrops
            Crisp.setZIndex(14);
        });
        Crisp.load();
        if (!hasIdentity) {
            // Will be eventually shown from the effect below if the user has identity
            Crisp.chat.hide();
        }
    }, []);
    useEffect(function() {
        if (hasIdentity) {
            isInjected && Crisp.chat.show();
            Crisp.session.onLoaded(function(hasIdentity) {
                // Should always be behind the modal,modalOld and sidebar - backdrops
                Crisp.setZIndex(14);
                if (hasIdentity) {
                    Crisp.chat.show();
                }
            });
        } else {
            isInjected && Crisp.chat.hide();
            Crisp.session.onLoaded(function() {
                // Should always be behind the modal,modalOld and sidebar - backdrops
                Crisp.setZIndex(14);
                Crisp.chat.hide();
            });
        }
    }, [
        hasIdentity,
        isInjected
    ]);
    return null;
}
