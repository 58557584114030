import { memoize } from "proxy-memoize";
import { authSelectors } from "./auth";
import { configSelectors } from "./config";
import { getLocalStorageItem } from "../features/app/utils";
/*******************
 * SELECTORS
 *******************/ export var intlSelectors = {
    getAppLocale: memoize(function(state) {
        var possibleLocales = configSelectors.locales(state);
        if (authSelectors.hasIdentity(state) && possibleLocales.includes(authSelectors.preferredLocale(state))) {
            return authSelectors.preferredLocale(state);
        }
        if (getLocalStorageItem("locale") && possibleLocales.includes(getLocalStorageItem("locale"))) {
            return getLocalStorageItem("locale");
        }
        return "ro-RO";
    // return configSelectors.locale(state);
    })
};
