export function getDecimalSeparator(locale) {
    return Intl.NumberFormat(locale).format(1.1).replace(RegExp("\\p{Number}", "gu"), "");
}
export function getThousandsSeparator(locale) {
    var numberWithDecimalSeparator = 1000.1;
    return Intl.NumberFormat(locale).formatToParts(numberWithDecimalSeparator).find(function(part) {
        return part.type === "group";
    }).value;
} // export function convertLocalizedNumberToFloat(value, locale) {
 //     if (!value) {
 //         return 0;
 //     }
 //
 //     return parseFloat(
 //         value.replaceAll(getNumberGroupSeparator(locale), "").replace(getDecimalSeparator(locale), ".") || 0,
 //     );
 // }
 // export function getInvalidNumberRule(locale) {
 //     const decimalSeparator = getDecimalSeparator(locale);
 //     const numberGroupSeparator = getNumberGroupSeparator(locale);
 //     //allows starting with 0...
 //     return new RegExp(
 //         `^\\d+([${escapeSpecialRegexCharacters(numberGroupSeparator)}]\\d+)*[${escapeSpecialRegexCharacters(
 //             decimalSeparator,
 //         )}]?\\d*?$`,
 //     );
 // }
 // export function escapeSpecialRegexCharacters(value) {
 //     // Ensure we don't break the regExp with variables
 //     return value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
 // }
 // export function getNumbersAndValidSeparatorsForLocalePattern(locale, maxNrOfDecimals = 2, allowNegative = true) {
 //     // Should allow only 0-9, decimalSeparator on keyPress and paste should not work if it contains anything else, also after decimal separator, only maxNrOfDecimals numbers are allowed
 //     if (!allowNegative) {
 //         return new RegExp(
 //             `^[0-9]*${escapeSpecialRegexCharacters(getDecimalSeparator(locale))}?[0-9]{0,${maxNrOfDecimals}}$`,
 //         );
 //     }
 //
 //     return new RegExp(
 //         `^-?[0-9]*${escapeSpecialRegexCharacters(getDecimalSeparator(locale))}?[0-9]{0,${maxNrOfDecimals}}$`,
 //     ); //todo ideal sa rezolvi aici sa poti pune . sau , (separator) la final
 // }
 // //todo new ->>>> unu pt doar nr, si altu pt doar nr cu x nr de zecimale si cazu 3 numar si separator de zecimale
 // export function handleOnlyNumbersAndValidSeparators(
 //     e,
 //     locale,
 //     handleChange,
 //     maxNrOfDecimals = 2,
 //     inputRef,
 //     allowNegative = true,
 // ) {
 //     let value = e.target.value;
 //
 //     value = value.replaceAll(getThousandsSeparator(locale), getDecimalSeparator(locale));
 //
 //     const pattern = getNumbersAndValidSeparatorsForLocalePattern(locale, maxNrOfDecimals, allowNegative);
 //
 //     const regMatch = pattern.test(value);
 //
 //     const cursorStart = e.target.selectionStart;
 //
 //     if (!regMatch) {
 //         const pat2 = new RegExp(`^[0-9]*${escapeSpecialRegexCharacters(getDecimalSeparator(locale))}$`);
 //         const regMatch2 = pat2.test(value);
 //         if (!regMatch2) {
 //             return;
 //         }
 //     }
 //
 //     if (value.includes(",")) {
 //         value = value.replace(",", ".");
 //     }
 //
 //     setTimeout(() => {
 //         inputRef.current?.setSelectionRange(cursorStart, cursorStart); // Restore cursor position if inputRef.current exists
 //     });
 //
 //     e.target.value = value;
 //     return handleChange(e);
 // }
