function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { captureException } from "@sentry/react";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { configSelectors } from "../../redux/config";
import { useSelector } from "react-redux";
export function useGoogleAnalyticsEvent() {
    var googleTagManagerId = useSelector(configSelectors.googleTagManagerId);
    if (!googleTagManagerId) {
        return function() {
            return null;
        };
    }
    return function(param) {
        var eventName = param.eventName, _param_params = param.params, params = _param_params === void 0 ? {} : _param_params;
        return TagManager.dataLayer({
            dataLayer: _object_spread({
                event: eventName
            }, params)
        });
    };
}
export default function GoogleTagManager() {
    var googleTagManagerId = useSelector(configSelectors.googleTagManagerId);
    useEffect(function() {
        if (!googleTagManagerId) {
            return;
        }
        try {
            TagManager.initialize({
                gtmId: googleTagManagerId
            });
        } catch (error) {
            captureException("Google analytics error");
            console.error(error);
        }
    }, []);
    return null;
}
