export var MY_COMPANY_ANAF_SPV_STATUSES = {
    ACTIVE: "active",
    INACTIVE: "inactive",
    EXPIRED: "expired",
    UNAUTHORIZED: "unauthorized",
    PENDING: "pending"
};
export var isAnafSpvInAnyActivatedState = function(status) {
    return [
        MY_COMPANY_ANAF_SPV_STATUSES.ACTIVE,
        MY_COMPANY_ANAF_SPV_STATUSES.UNAUTHORIZED,
        MY_COMPANY_ANAF_SPV_STATUSES.PENDING
    ].includes(status);
};
