import { memoize } from "proxy-memoize";
import { createReducer } from "@reduxjs/toolkit";
/*******************
 * ACTION TYPES
 *******************/ var actionTypes = {
    LOAD: "config/LOAD"
};
/*******************
 * REDUCER
 *******************/ export default createReducer({}, function(builder) {
    builder.addCase(actionTypes.LOAD, function(state, action) {
        return action.payload;
    });
});
/*******************
 * ACTIONS
 *******************/ export var configActions = {
    loadConfig: function loadConfig(configData) {
        return function(dispatch) {
            return dispatch({
                type: actionTypes.LOAD,
                payload: configData
            });
        };
    }
};
/*******************
 * SELECTORS
 *******************/ export var configSelectors = {
    apiHost: memoize(function(state) {
        return state.config.services.apiHost;
    }),
    sentryEnabled: memoize(function(state) {
        return state.config.services.sentry.enabled;
    }),
    sentryDsn: memoize(function(state) {
        return state.config.services.sentry.dsn;
    }),
    sentryReplaysSessionSampleRate: memoize(function(state) {
        return state.config.services.sentry.replaysSessionSampleRate;
    }),
    sentryTracesSampleRate: memoize(function(state) {
        return state.config.services.sentry.tracesSampleRate;
    }),
    sentryAuthToken: memoize(function(state) {
        return state.config.services.sentry.authToken;
    }),
    crispWebsiteId: memoize(function(state) {
        return state.config.services.crispWebsiteId;
    }),
    posthogAppId: memoize(function(state) {
        return state.config.services.posthogAppId;
    }),
    env: memoize(function(state) {
        return state.config.app.env;
    }),
    version: memoize(function(state) {
        return state.config.app.version;
    }),
    debug: memoize(function(state) {
        return state.config.app.debug;
    }),
    appUrl: memoize(function(state) {
        return state.config.app.appUrl;
    }),
    locales: memoize(function(state) {
        return state.config.app.locales;
    }),
    activateEfacturaBlogUrl: memoize(function(state) {
        return state.config.docs.activateEfacturaBlogUrl;
    }),
    downloadUploadEfacturaBlogUrl: memoize(function(state) {
        return state.config.docs.downloadUploadEfacturaBlogUrl;
    }),
    digitalCertificateBlogUrl: memoize(function(state) {
        return state.config.docs.digitalCertificateBlogUrl;
    }),
    invoiceAttachmentsBlogUrl: memoize(function(state) {
        return state.config.docs.invoiceAttachmentsBlogUrl;
    }),
    termsOfServiceUrl: memoize(function(state) {
        return state.config.docs.termsOfServiceUrl;
    }),
    privacyPolicyUrl: memoize(function(state) {
        return state.config.docs.privacyPolicyUrl;
    }),
    dpaUrl: memoize(function(state) {
        return state.config.docs.dpaUrl;
    }),
    googleTagManagerId: memoize(function(state) {
        return state.config.services.googleTagManagerId;
    }),
    pusherAppKey: memoize(function(state) {
        return state.config.services.pusherAppKey;
    }),
    dataProcessingAddendumUrl: memoize(function(state) {
        return state.config.docs.dataProcessingAddendumUrl;
    }),
    publishInvoiceHelpUrl: memoize(function(state) {
        return state.config.docs.publishInvoiceHelpUrl;
    }),
    updatesModalDetailsUrl: memoize(function(state) {
        return state.config.docs.updatesModalDetailsUrl;
    }),
    isEnvProduction: memoize(function(state) {
        return state.config.app.env === "production";
    })
};
