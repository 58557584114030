import { useEffect } from "react";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { screenBreakpointsActions } from "../../redux/screenBreakpoints";
ScreenWidthUpdater.propTypes = {
    children: PropTypes.node
};
export default function ScreenWidthUpdater() {
    var dispatch = useDispatch();
    useEffect(function() {
        var handleResize = debounce(function() {
            var screenWidth = window.innerWidth;
            dispatch(screenBreakpointsActions.updateScreenWidth(screenWidth));
        }, 100);
        handleResize(); // Call initially to set the initial screen width
        window.addEventListener("resize", handleResize);
        return function() {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return null;
}
