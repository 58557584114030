function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { addMethod, object, ref, string } from "yup";
import { base64ToArrayBuffer, DEFAULT_CURRENCY_CODE, parseFromUtc } from "../app/utils";
import { parseDateWithoutTime } from "../app/utils";
import { differenceInCalendarDays } from "date-fns";
import { getErrorMessageTranslated, mapErrorByHttpStatusCode } from "../../hooks/useErrorMessage";
import Big from "big.js";
import FileSaver from "file-saver";
import { etransportActions } from "../../redux/etransport";
import { authSelectors } from "../../redux/auth";
import { ANAF_SPV_RO_E_TRANSPORT_DELETE, ANAF_SPV_RO_E_TRANSPORT_READ, ANAF_SPV_RO_E_TRANSPORT_WRITE } from "../Authorization/permissions";
export var ETRANSPORT_DECLARATION_TYPES = {
    SUPPLIER: "supplier",
    CLIENT: "client"
};
export var ETRANSPORT_CONFIRMATION_TYPES = {
    CONFIRMED: "10",
    PARTIALLY_CONFIRMED: "20",
    NOT_CONFIRMED: "30"
};
export var ETRANSPORT_LISTING_EVENT_TYPES = {
    DECLARATION: "declaration",
    MODIFY_VEHICLE: "vehicle-modification",
    CONFIRM_TRANSPORT: "transport-confirmation"
};
export var MAX_DELIVERY_DATE_INTERVAL_DAYS = 3;
export var validLicenseRegex = /^[a-zA-Z0-9]+$/;
export function getUitWithDashes(uit) {
    if ([
        null,
        undefined,
        ""
    ].includes(uit)) {
        return uit;
    }
    if (typeof uit === "number") {
        uit = uit.toString();
    }
    if (typeof uit !== "string") {
        throw new TypeError("UIT must be a string (or number or undefined or null)");
    }
    var chunks = uit.match(/.{1,4}/g);
    return chunks ? chunks.join("-") : "";
}
export function selectedCompanyHasEtransportPermission(param) {
    var dispatch = param.dispatch, typeOfPermission = param.typeOfPermission;
    return dispatch(function(dispatch, getState) {
        return authSelectors.hasPermission(getState())(typeOfPermission);
    });
}
export function getEtransportInfo(param) {
    var item = param.item, currentDate = param.currentDate, myCompanyAnafSpvStatusIsActive = param.myCompanyAnafSpvStatusIsActive, dispatch = param.dispatch;
    var selectedCompanyHasEtransportWritePermission = selectedCompanyHasEtransportPermission({
        dispatch: dispatch,
        typeOfPermission: ANAF_SPV_RO_E_TRANSPORT_WRITE
    });
    var selectedCompanyHasEtransportReadPermission = selectedCompanyHasEtransportPermission({
        dispatch: dispatch,
        typeOfPermission: ANAF_SPV_RO_E_TRANSPORT_READ
    });
    var selectedCompanyHasEtransportDeletePermission = selectedCompanyHasEtransportPermission({
        dispatch: dispatch,
        typeOfPermission: ANAF_SPV_RO_E_TRANSPORT_DELETE
    });
    var type = item.type;
    var isDeclarationType = type === ETRANSPORT_LISTING_EVENT_TYPES.DECLARATION;
    var isVehicleModificationType = type === ETRANSPORT_LISTING_EVENT_TYPES.MODIFY_VEHICLE;
    var isTransportConfirmationType = type === ETRANSPORT_LISTING_EVENT_TYPES.CONFIRM_TRANSPORT;
    var eventStatus = item.etransportStatus;
    var documentStatus = item.status;
    var isDocumentStatusImported = documentStatus === ETRANSPORT_DOCUMENT_STATUSES.IMPORTED;
    var isCorrectionDeclaration = !!item.parentDeclarationId;
    var isDocumentStatusImportedCorrected = documentStatus === ETRANSPORT_DOCUMENT_STATUSES.IMPORTED_CORRECTED;
    var isDocumentStatusPublished = documentStatus === ETRANSPORT_DOCUMENT_STATUSES.PUBLISHED;
    var isDocumentStatusDraft = documentStatus === ETRANSPORT_DOCUMENT_STATUSES.DRAFT;
    var isDocumentStatusCorrected = documentStatus === ETRANSPORT_DOCUMENT_STATUSES.CORRECTED;
    var isDocumentStatusCanceled = documentStatus === ETRANSPORT_DOCUMENT_STATUSES.CANCELED;
    // const isDocumentStatusCancelDraft = documentStatus === ETRANSPORT_DOCUMENT_STATUSES.CANCEL_DRAFT;
    var isEventStatusPending = eventStatus === ETRANSPORT_EVENT_STATUSES.PENDING;
    var isEventStatusRejected = eventStatus === ETRANSPORT_EVENT_STATUSES.REJECTED;
    var isEventStatusAccepted = eventStatus === ETRANSPORT_EVENT_STATUSES.ACCEPTED;
    var isEventStatusNull = eventStatus === ETRANSPORT_EVENT_STATUSES.NULL;
    // const isEventStatusPendingRejectedOrAccepted =
    //     isEventStatusRejected || isEventStatusPending || isEventStatusAccepted;
    var postEtransportFailure = item.postEtransportFailure;
    /**
     * Ar trebui sa vina corect de pe backend:
     * daca e declaration, folosesti deliveryDate
     * daca e modify vehicle folosesti date
     * daca e confirmation folosesti acceptedInEtransportAt daca ai, sau registeredInEtransportAt sau createdAt
     * */ var combinedDate = item.combinedDate;
    var parsedDeliveryDate = combinedDate ? isVehicleModificationType || isTransportConfirmationType ? parseFromUtc(combinedDate) : parseDateWithoutTime(item.deliveryDate) : undefined;
    var diffInDays = parsedDeliveryDate ? differenceInCalendarDays(parsedDeliveryDate, currentDate) : undefined;
    // const isNationalTransport = item.isNationalTransport === OPERATION_TYPES.NATIONAL_TRANSPORT;
    // const isInValidIntervalToCreateConfirmationAndModification =
    //     diffInDays <= 0 && //? deliveryDate today or in the past
    //     ((isNationalTransport && diffInDays <= 5) || //? deliveryDate is within 5 days for national transport (past)
    //         (!isNationalTransport && diffInDays <= 15)); //? deliveryDate is within 15 days for non-national transport (past)
    var hasUit = item.uit;
    function canModifyOrConfirm() {
        if (!selectedCompanyHasEtransportWritePermission) {
            return false;
        }
        if (!isDeclarationType) {
            return false;
        }
        if (isDocumentStatusImported) {
            return true;
        }
        if (isDocumentStatusImportedCorrected) {
            return false;
        }
        if (isEventStatusPending) {
            return false;
        }
        if (isDocumentStatusDraft && hasUit && !isCorrectionDeclaration) {
            return true;
        }
        if (isDocumentStatusPublished) {
            return true;
        }
        return false;
    }
    function canDownloadPdfAndSendByEmail() {
        if (!selectedCompanyHasEtransportReadPermission) {
            return false;
        }
        if (!isDeclarationType) {
            return false;
        }
        if (!hasUit) {
            return false;
        }
        if (isDocumentStatusImported || isDocumentStatusImportedCorrected) {
            return true;
        }
        if (!isDocumentStatusPublished) {
            return false;
        }
        return true;
    }
    return {
        //! ACTIONS METHODS
        canCancelDeclaration: function() {
            if (!selectedCompanyHasEtransportDeletePermission) {
                return false;
            }
            if (!isDeclarationType) {
                return false;
            }
            if (isEventStatusPending || isDocumentStatusImported) {
                return false;
            }
            if (isDocumentStatusPublished) {
                return true;
            }
            return false;
        },
        canDelete: function() {
            if (!selectedCompanyHasEtransportDeletePermission) {
                return false;
            }
            if (isVehicleModificationType || isTransportConfirmationType) {
                if (!isEventStatusPending && isDocumentStatusDraft) {
                    return true;
                }
                return false;
            }
            if (isDeclarationType) {
                if (isEventStatusPending) {
                    return false;
                }
                if (isDocumentStatusDraft && isCorrectionDeclaration) {
                    return true;
                }
                if (!isDocumentStatusImported && !hasUit) {
                    if (isDocumentStatusDraft && (isEventStatusRejected || isEventStatusNull)) {
                        return true;
                    }
                    if (isDocumentStatusPublished && isEventStatusAccepted) {
                        return true;
                    }
                }
                return false;
            }
        },
        canViewConfirmation: function() {
            if (!selectedCompanyHasEtransportReadPermission) {
                return false;
            }
            if (!isTransportConfirmationType) {
                return false;
            }
            if (isDocumentStatusDraft && isEventStatusPending) {
                return true;
            }
            if (isDocumentStatusPublished && isEventStatusAccepted) {
                return true;
            }
            return false;
        },
        canEditConfirmation: function() {
            if (!selectedCompanyHasEtransportWritePermission) {
                return false;
            }
            if (!isTransportConfirmationType) {
                return false;
            }
            if (isEventStatusPending) {
                return false;
            }
            if (!isDocumentStatusDraft) {
                return false;
            }
            return true;
        },
        canViewVehicleModification: function() {
            if (!selectedCompanyHasEtransportReadPermission) {
                return false;
            }
            if (!isVehicleModificationType) {
                return false;
            }
            if (isDocumentStatusDraft && isEventStatusPending) {
                return true;
            }
            if (isDocumentStatusPublished && isEventStatusAccepted) {
                return true;
            }
            return false;
        },
        canEditVehicleModification: function() {
            if (!selectedCompanyHasEtransportWritePermission) {
                return false;
            }
            if (!isVehicleModificationType) {
                return false;
            }
            if (isEventStatusPending) {
                return false;
            }
            if (!isDocumentStatusDraft) {
                return false;
            }
            return true;
        },
        canCreateConfirmation: canModifyOrConfirm,
        canCreateVehicleModification: canModifyOrConfirm,
        canViewDeclaration: function() {
            return selectedCompanyHasEtransportReadPermission && isDeclarationType;
        },
        canEditDeclaration: function() {
            if (!selectedCompanyHasEtransportWritePermission) {
                return false;
            }
            if (!isDeclarationType) {
                return false;
            }
            if (isEventStatusPending) {
                return false;
            }
            if (isDocumentStatusDraft) {
                return true;
            }
            return false;
        },
        canAddDeclarationCorrection: function() {
            if (!selectedCompanyHasEtransportWritePermission) {
                return false;
            }
            if (!isDeclarationType) {
                return false;
            }
            if (isDocumentStatusImported) {
                return false;
            }
            if (isEventStatusPending) {
                return false;
            }
            if (!isDocumentStatusPublished) {
                return false;
            }
            return true;
        },
        canSendToEtransport: function() {
            if (!selectedCompanyHasEtransportWritePermission) {
                return false;
            }
            if (!myCompanyAnafSpvStatusIsActive) {
                return false;
            }
            if (isDeclarationType) {
                if (isDocumentStatusImported || isDocumentStatusImportedCorrected || isEventStatusPending) {
                    return false;
                }
                if (isEventStatusRejected && isDocumentStatusPublished) {
                    return false;
                }
                if (isEventStatusAccepted && (isDocumentStatusCanceled || isDocumentStatusCorrected || isDocumentStatusPublished)) return false;
                return true;
            }
            if (isVehicleModificationType || isTransportConfirmationType) {
                if (!isDocumentStatusDraft || isEventStatusPending) {
                    return false;
                }
                return true;
            }
        },
        canDownloadEtransportXml: function() {
            if (!selectedCompanyHasEtransportReadPermission) {
                return false;
            }
            if (isDeclarationType) {
                if (isDocumentStatusImported || isDocumentStatusImportedCorrected) {
                    return false;
                }
                return true;
            }
            if (isVehicleModificationType || isTransportConfirmationType) {
                return true;
            }
            return false;
        },
        canDuplicate: function() {
            if (!selectedCompanyHasEtransportWritePermission) {
                return false;
            }
            if (!isDeclarationType) {
                return false;
            }
            if (isDocumentStatusImported || isDocumentStatusImportedCorrected) {
                return false;
            }
            return true;
        },
        canDownloadPdf: canDownloadPdfAndSendByEmail,
        canSendByEmail: canDownloadPdfAndSendByEmail,
        //! CONSTS
        type: type,
        isDeclarationType: isDeclarationType,
        isDocumentStatusDraft: isDocumentStatusDraft,
        isVehicleModificationType: isVehicleModificationType,
        isTransportConfirmationType: isTransportConfirmationType,
        postEtransportFailure: postEtransportFailure,
        parsedDeliveryDate: parsedDeliveryDate,
        diffInDays: diffInDays,
        isCorrectionDeclaration: isCorrectionDeclaration,
        isDocumentStatusImportedCorrected: isDocumentStatusImportedCorrected,
        isDocumentStatusImported: isDocumentStatusImported
    };
}
export var ETRANSPORT_COMBINED_STATUSES = {
    PENDING: "pending",
    REJECTED: "rejected",
    CANCELED: "canceled",
    ACCEPTED: "accepted",
    DRAFT: "draft",
    CORRECTED: "corrected",
    IMPORTED: "imported"
};
export var ETRANSPORT_EVENT_STATUSES = {
    PENDING: "pending",
    REJECTED: "rejected",
    ACCEPTED: "accepted",
    NULL: null
};
export var ETRANSPORT_DOCUMENT_STATUSES = {
    DRAFT: "draft",
    PUBLISHED: "published",
    CORRECTED: "corrected",
    CANCELED: "canceled",
    CANCEL_DRAFT: "cancel-draft",
    IMPORTED: "imported",
    IMPORTED_CORRECTED: "imported:corrected"
};
//???
export var OPERATION_TYPES = {
    EU_IMPORT: "10",
    IOHN_OPERATION_EU_IMPORT: "12",
    CALL_OF_STOCK_IMPORT: "14",
    EU_EXPORT: "20",
    IOHN_OPERATION_EU_EXPORT: "22",
    CALL_OF_STOCK_EXPORT: "24",
    NATIONAL_TRANSPORT: "30",
    NON_EU_IMPORT: "40",
    NON_EU_EXPORT: "50",
    EU_CHANGE_TRANSPORT_OR_DEPOSIT_IMPORT: "60",
    EU_CHANGE_TRANSPORT_OR_DEPOSIT_EXPORT: "70"
};
export var CLIENT_OPERATION_TYPES = [
    OPERATION_TYPES.EU_IMPORT,
    OPERATION_TYPES.IOHN_OPERATION_EU_IMPORT,
    OPERATION_TYPES.CALL_OF_STOCK_IMPORT,
    OPERATION_TYPES.NON_EU_IMPORT,
    OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_IMPORT
];
export var SUPPLIER_OPERATION_TYPES = [
    OPERATION_TYPES.EU_EXPORT,
    OPERATION_TYPES.IOHN_OPERATION_EU_EXPORT,
    OPERATION_TYPES.CALL_OF_STOCK_EXPORT,
    OPERATION_TYPES.NON_EU_EXPORT,
    OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_EXPORT,
    OPERATION_TYPES.NATIONAL_TRANSPORT
];
export var PRODUCT_OPERATION_TYPES = {
    TRADING: "101",
    PRODUCTION: "201",
    FREE: "301",
    COMMERCIAL_EQUIPMENT: "401",
    ASSET: "501",
    OWN_USAGE: "601",
    DELIVERY_AND_INSTALL: "703",
    TRANSFER_WAREHOUSE: "704",
    CLIENT_GOODS: "705",
    LEASING: "801",
    WARRANTY_GOODS: "802",
    EXEMPTED_OPERATION: "901",
    INVESTMENT_IN_PROGRESS: "1001",
    DONATION: "1101",
    OTHER: "9901",
    SAME_WITH_DECLARATION_OPERATION: "9999"
};
export var DOCUMENTS_TYPES = {
    CMR_NOTE: "10",
    INVOICE: "20",
    DELIVERY_NOTE: "30",
    OTHER: "9999"
};
export var ADDRESSES_TYPES = {
    ADDRESS: "address",
    BORDER_CROSSING_POINT: "border-crossing-point",
    CUSTOMS_OFFICE: "customs-office"
};
export var licensePlatesValidator = object().shape({
    deliveryVehicleRegistrationNumber: string().required("required").max(20, "max:20").min(2, "min:2").matches(validLicenseRegex, "onlyAlphaNumeric").test("unique", "unique", function(value, context) {
        var _context_resolve, _context_resolve1;
        return (value === null || value === void 0 ? void 0 : value.toLowerCase()) !== ((_context_resolve = context.resolve(ref("deliveryVehicleTrailerRegistrationNumber"))) === null || _context_resolve === void 0 ? void 0 : _context_resolve.toLowerCase()) && (value === null || value === void 0 ? void 0 : value.toLowerCase()) !== ((_context_resolve1 = context.resolve(ref("deliveryVehicleSecondTrailerRegistrationNumber"))) === null || _context_resolve1 === void 0 ? void 0 : _context_resolve1.toLowerCase());
    }),
    deliveryVehicleTrailerRegistrationNumber: string().max(20, "max:20").min(2, "min:2").matches(validLicenseRegex, "onlyAlphaNumeric"),
    deliveryVehicleSecondTrailerRegistrationNumber: string().max(20, "max:20").min(2, "min:2").matches(validLicenseRegex, "onlyAlphaNumeric")
});
export var eTransportValidationSchema = object().shape(_object_spread_props(_object_spread({
    partnerId: string().required("required"),
    referenceCode: string().max(50, "max:50"),
    currencyCode: string().required("required"),
    currencyRate: string().test("required", "required", function(value, context) {
        if (!value && DEFAULT_CURRENCY_CODE !== context.parent.currencyCode) {
            return context.createError({
                path: context.path,
                message: "required"
            });
        }
        return true;
    }),
    deliveryDate: string().required("required"),
    deliveryPartnerId: string().required("required")
}, licensePlatesValidator.fields), {
    myCompanyDeliveryAddressId: string().test("required", "required", function(value, context) {
        if (!value && (context.parent.endAddressType === ADDRESSES_TYPES.ADDRESS && CLIENT_OPERATION_TYPES.includes(context.parent.operationType) || context.parent.startAddressType === ADDRESSES_TYPES.ADDRESS && SUPPLIER_OPERATION_TYPES.includes(context.parent.operationType))) {
            return context.createError({
                path: context.path,
                message: "required"
            });
        }
        return true;
    }),
    partnerDeliveryAddressId: string().test("required", "required", function(value, context) {
        if (!value && (context.parent.endAddressType === ADDRESSES_TYPES.ADDRESS && SUPPLIER_OPERATION_TYPES.includes(context.parent.operationType) || context.parent.startAddressType === ADDRESSES_TYPES.ADDRESS && CLIENT_OPERATION_TYPES.includes(context.parent.operationType))) {
            return context.createError({
                path: context.path,
                message: "required"
            });
        }
        return true;
    }),
    startAddressBorderCrossingPoint: string().test("required", "required", function(value, context) {
        if (!value && context.parent.startAddressType === ADDRESSES_TYPES.BORDER_CROSSING_POINT) {
            return context.createError({
                path: context.path,
                message: "required"
            });
        }
        return true;
    }),
    endAddressBorderCrossingPoint: string().test("required", "required", function(value, context) {
        if (!value && context.parent.endAddressType === ADDRESSES_TYPES.BORDER_CROSSING_POINT) {
            return context.createError({
                path: context.path,
                message: "required"
            });
        }
        return true;
    }),
    startAddressCustomsOffice: string().test("required", "required", function(value, context) {
        if (!value && context.parent.startAddressType === ADDRESSES_TYPES.CUSTOMS_OFFICE) {
            return context.createError({
                path: context.path,
                message: "required"
            });
        }
        return true;
    }),
    endAddressCustomsOffice: string().test("required", "required", function(value, context) {
        if (!value && context.parent.endAddressType === ADDRESSES_TYPES.CUSTOMS_OFFICE) {
            return context.createError({
                path: context.path,
                message: "required"
            });
        }
        return true;
    })
}));
export var defaultRulesDefinitions = {
    defaultPriceInput: {
        nbDecimals: 4,
        trimDecimals: true,
        allowNegative: false
    }
};
export var rulesDefinitions = {
    //numberInputs...
    quantity: {
        nbDecimals: 2,
        max: 999999999999.99,
        min: 0.01,
        allowNegative: false,
        trimDecimals: true
    },
    netWeight: {
        nbDecimals: 2,
        max: 9999999999.99,
        min: 0.01,
        allowNegative: false,
        trimDecimals: true
    },
    grossWeight: {
        nbDecimals: 2,
        max: 9999999999.99,
        min: 0.01,
        allowNegative: false,
        trimDecimals: true
    },
    price: {
        max: 999999999999.9999,
        min: 0.01,
        allowNegative: defaultRulesDefinitions.defaultPriceInput.allowNegative,
        trimDecimals: defaultRulesDefinitions.defaultPriceInput.trimDecimals,
        nbDecimals: defaultRulesDefinitions.defaultPriceInput.nbDecimals
    }
};
export var isValidNumber = function(value) {
    // Helper function to check if a value is a valid number (used in rowHook)
    try {
        // return true;
        return value !== undefined && value !== "" && !isNaN(value) && Big(value).toString() === value.toString();
    } catch (e) {
        return false;
    }
};
// Define the custom validation function for number inputs (and price input since its a numberInput as well)
var numberInputFn = function numberInputFn(rules) {
    return this.test("numberInput", "Invalid number format", function(value) {
        var _this = this, path = _this.path, createError = _this.createError;
        var nbDecimals = rules.nbDecimals, max = rules.max, min = rules.min, allowNegative = rules.allowNegative;
        // If value is empty or undefined, skip the validation
        if (value === undefined || value === null || value === "") {
            return true; // Assume required validation will handle empty values
        }
        // Get locale-specific separators
        // const locale = numbro.language();
        // const decimalSeparator = getDecimalSeparator(locale);
        // const thousandsSeparator = getThousandsSeparator(locale);
        // Remove the suffix if it exists //NOT OK, DELETE //todo x313
        // if (suffix) {
        //     const valueLower = value.toLowerCase();
        //     const suffixLower = suffix.toLowerCase();
        //
        //     if (valueLower.endsWith(suffixLower)) {
        //         console.log(201, "suffix was", suffix);
        //         value = value.slice(0, -suffix.length).trim();
        //     }
        // }
        // Remove thousand separators and replace decimal separator with a dot
        // const cleanedValue = value; // todo x316 ->> rezulta in bug adica.. 44.5 o sa l vada ca 445... //!IMPORTANT
        // .split(thousandsSeparator)
        // .join("") // Remove thousand separators
        // .replace(decimalSeparator, "."); // Replace decimal separator with '.'
        var cleanedValue = value.trim();
        // Validate the entire string to ensure it's a correct number
        var isValidNumber = /^-?\d+(\.\d+)?$/.test(cleanedValue);
        var numValue = parseFloat(cleanedValue);
        //todo x313 ->> maybe suffix should not be here so.. return format if
        // suffix exists?  orrr some other err (probably not in translation
        // so it wont be rendered) but remove the suffix when setting the product lines????
        if (!isValidNumber || isNaN(numValue)) {
            return createError({
                path: path,
                message: "numeric"
            });
        }
        // Check for max/min limits
        if (max !== undefined && numValue > max) {
            return createError({
                path: path,
                message: "max:".concat(max)
            });
        }
        if (min !== undefined && numValue < min) {
            return createError({
                path: path,
                message: "min:".concat(min)
            });
        }
        // Check for negative values if not allowed
        if (!allowNegative && numValue < 0) {
            return createError({
                path: path,
                message: "min:".concat(min)
            });
        }
        // Check if num is not negative but max was negative
        if (allowNegative && max < 0 && numValue >= 0) {
            return createError({
                path: path,
                message: "max:".concat(max)
            });
        }
        // Check if num is not negative(including 0) when max is 0
        if (allowNegative && max === 0 && numValue > 0) {
            return createError({
                path: path,
                message: "max:".concat(max)
            });
        }
        // Check decimal precision
        var decimalPart = cleanedValue.split(".")[1];
        if (decimalPart && decimalPart.length > nbDecimals) {
            return createError({
                path: path,
                message: "decimal:0-".concat(nbDecimals)
            });
        }
        return true;
    });
};
//! Extend yup with custom number validation methods
addMethod(string, "numberInput", numberInputFn);
export var declarationLineValidator = function(operationType) {
    return object().shape({
        operationType: string().required("required"),
        productName: string().required("required").max(200, "max:200"),
        referenceCode: string().max(50, "max:50"),
        measurementUnitCode: string().required("required"),
        quantity: string().numberInput(rulesDefinitions.quantity).test("required", "required", function(value, context) {
            if (!value || value === "" || value === "0") {
                return context.createError({
                    path: context.path,
                    message: "required"
                });
            }
            return true;
        }),
        price: string().numberInput(rulesDefinitions.price).test("required", "required", function(value, context) {
            if ((!value || value === "0") && operationType !== OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_IMPORT && operationType !== OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_EXPORT) {
                return context.createError({
                    path: context.path,
                    message: "required"
                });
            }
            return true;
        }),
        netWeight: string().numberInput(rulesDefinitions.netWeight).test("required", "required", function(value, context) {
            if ((!value || value === "0") && operationType !== OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_IMPORT && operationType !== OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_EXPORT) {
                return context.createError({
                    path: context.path,
                    message: "required"
                });
            }
            return true;
        }).test("lte:grossWeight", "lte:grossWeight", function(value, context) {
            if (value && context.parent.grossWeight && +value > +context.parent.grossWeight) {
                return context.createError({
                    path: context.path,
                    message: "lte:".concat(context.parent.grossWeight)
                });
            }
            return true;
        }),
        grossWeight: string().numberInput(rulesDefinitions.grossWeight).test("required", "required", function(value, context) {
            if (!value || value === "" || value === "0") {
                return context.createError({
                    path: context.path,
                    message: "required"
                });
            }
            return true;
        }).test("gte:netWeight", "gte:netWeight", function(value, context) {
            if (value && context.parent.netWeight && +value < +context.parent.netWeight) {
                return context.createError({
                    path: context.path,
                    message: "gte:".concat(context.parent.netWeight)
                });
            }
            return true;
        }),
        productEuCnClassificationCode: string().max(12, "max:12").test("required", "required", function(value, context) {
            if (!value && operationType !== OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_IMPORT && operationType !== OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_EXPORT) {
                return context.createError({
                    path: context.path,
                    message: "required"
                });
            }
            return true;
        })
    });
};
export function getReferenceProhibited(operationType) {
    return [
        OPERATION_TYPES.NATIONAL_TRANSPORT,
        OPERATION_TYPES.NON_EU_IMPORT,
        OPERATION_TYPES.NON_EU_EXPORT,
        OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_IMPORT,
        OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_EXPORT
    ].includes(operationType);
}
export var borderCrossingPointsList = [
    {
        value: "32",
        label: "Albița (MD)"
    },
    {
        value: "6",
        label: "Bechet (BG)"
    },
    {
        value: "2",
        label: "Borș (HU)"
    },
    {
        value: "38",
        label: "Borș 2 - A3 (HU)"
    },
    {
        value: "5",
        label: "Calafat (BG)"
    },
    {
        value: "13",
        label: "Călărași (BG)"
    },
    {
        value: "16",
        label: "Carei (HU)"
    },
    {
        value: "17",
        label: "Cenad (HU)"
    },
    {
        value: "35",
        label: "Constanța Sud Agigea"
    },
    {
        value: "14",
        label: "Corabia (BG)"
    },
    {
        value: "18",
        label: "Episcopia Bihor (HU)"
    },
    {
        value: "34",
        label: "Galați Giurgiulești (MD)"
    },
    {
        value: "9",
        label: "Giurgiu (BG)"
    },
    {
        value: "29",
        label: "Halmeu (UA)"
    },
    {
        value: "28",
        label: "Jimbolia (RS)"
    },
    {
        value: "4",
        label: "Nădlac (HU)"
    },
    {
        value: "37",
        label: "Nădlac 2 - A1 (HU)"
    },
    {
        value: "26",
        label: "Naidăș (RS)"
    },
    {
        value: "11",
        label: "Negru Vodă (BG)"
    },
    {
        value: "33",
        label: "Oancea (MD)"
    },
    {
        value: "15",
        label: "Oltenița (BG)"
    },
    {
        value: "10",
        label: "Ostrov (BG)"
    },
    {
        value: "1",
        label: "Petea (HU)"
    },
    {
        value: "25",
        label: "Porțile de Fier 1 (RS)"
    },
    {
        value: "20",
        label: "Săcuieni (HU)"
    },
    {
        value: "19",
        label: "Salonta (HU)"
    },
    {
        value: "31",
        label: "Sculeni (MD)"
    },
    {
        value: "36",
        label: "Siret (UA)"
    },
    {
        value: "27",
        label: "Stamora Moravița (RS)"
    },
    {
        value: "30",
        label: "Stânca Costești (MD)"
    },
    {
        value: "21",
        label: "Turnu (HU)"
    },
    {
        value: "7",
        label: "Turnu Măgurele (BG)"
    },
    {
        value: "22",
        label: "Urziceni (HU)"
    },
    {
        value: "23",
        label: "Valea lui Mihai (HU)"
    },
    {
        value: "12",
        label: "Vama Veche (BG)"
    },
    {
        value: "3",
        label: "Vărșand (HU)"
    },
    {
        value: "24",
        label: "Vladimirescu (HU)"
    },
    {
        value: "8",
        label: "Zimnicea (BG)"
    }
];
export var customsOfficeList = [
    {
        value: "242901",
        label: "BVF Aero Baia Mare (ROCJ0510)"
    },
    {
        value: "362902",
        label: "BVF Aeroport Delta Dunării Tulcea (ROGL8910)"
    },
    {
        value: "302902",
        label: "BVF Aeroport Satu Mare (ROCJ7830)"
    },
    {
        value: "372902",
        label: "BVF Albiţa (ROIS0100)"
    },
    {
        value: "22901",
        label: "BVF Arad Aeroport (ROTM0230)"
    },
    {
        value: "42901",
        label: "BVF Bacău Aeroport (ROIS0620)"
    },
    {
        value: "402901",
        label: "BVF Băneasa (ROBU1040)"
    },
    {
        value: "162902",
        label: "BVF Bechet (ROCR1720)"
    },
    {
        value: "92902",
        label: "BVF Brăila (ROGL0700)"
    },
    {
        value: "162903",
        label: "BVF Calafat (ROCR1700)"
    },
    {
        value: "122901",
        label: "BVF Cluj Napoca Aero (ROCJ1810)"
    },
    {
        value: "132904",
        label: "BVF Constanţa Port (ROCT1970)"
    },
    {
        value: "132901",
        label: "BVF Constanţa Sud Agigea (ROCT1900)"
    },
    {
        value: "162901",
        label: "BVF Craiova Aeroport (ROCR2110)"
    },
    {
        value: "332901",
        label: "BVF Dorneşti (ROIS2700)"
    },
    {
        value: "252904",
        label: "BVF Drobeta Turnu Severin (ROCR9000)"
    },
    {
        value: "372901",
        label: "BVF Fălciu (-)"
    },
    {
        value: "172904",
        label: "BVF Galaţi (ROGL3800)"
    },
    {
        value: "172902",
        label: "BVF Giurgiuleşti (ROGL3850)"
    },
    {
        value: "302901",
        label: "BVF Halmeu (ROCJ4310)"
    },
    {
        value: "222903",
        label: "BVF Iaşi (ROIS4650)"
    },
    {
        value: "222901",
        label: "BVF Iaşi Aero (ROIS4660)"
    },
    {
        value: "362904",
        label: "BVF Isaccea (ROGL8920)"
    },
    {
        value: "352901",
        label: "BVF Jimbolia (ROTM5010)"
    },
    {
        value: "132903",
        label: "BVF Mangalia (ROCT5400)"
    },
    {
        value: "132902",
        label: "BVF Mihail Kogălniceanu (ROCT5100)"
    },
    {
        value: "352902",
        label: "BVF Moraviţa (ROTM5510)"
    },
    {
        value: "112901",
        label: "BVF Naidăș (ROTM6100)"
    },
    {
        value: "172903",
        label: "BVF Oancea (ROGL3610)"
    },
    {
        value: "52901",
        label: "BVF Oradea Aeroport (ROCJ6580)"
    },
    {
        value: "252901",
        label: "BVF Orşova (ROCR7280)"
    },
    {
        value: "232901",
        label: "BVF Otopeni Călători (ROBU1030)"
    },
    {
        value: "252902",
        label: "BVF Porţile De Fier I (ROCR7270)"
    },
    {
        value: "252903",
        label: "BVF Porţile De Fier II (ROCR7200)"
    },
    {
        value: "72902",
        label: "BVF Rădăuţi Prut (ROIS1620)"
    },
    {
        value: "222902",
        label: "BVF Sculeni (ROIS4990)"
    },
    {
        value: "322901",
        label: "BVF Sibiu Aeroport (ROBV7910)"
    },
    {
        value: "242902",
        label: "BVF Sighet (ROCJ8000)"
    },
    {
        value: "332902",
        label: "BVF Siret (ROIS8200)"
    },
    {
        value: "72901",
        label: "BVF Stanca Costeşti (ROIS1610)"
    },
    {
        value: "332903",
        label: "BVF Suceava Aero (ROIS8250)"
    },
    {
        value: "362901",
        label: "BVF Sulina (ROCT8300)"
    },
    {
        value: "262901",
        label: "BVF Târgu Mureş Aeroport (ROBV8820)"
    },
    {
        value: "352903",
        label: "BVF Timişoara Aeroport (ROTM8730)"
    },
    {
        value: "362903",
        label: "BVF Tulcea (ROGL8900)"
    },
    {
        value: "342901",
        label: "BVF Turnu Măgurele (ROCR9100)"
    },
    {
        value: "332904",
        label: "BVF Vicovu De Sus (ROIS9620)"
    },
    {
        value: "342902",
        label: "BVF Zimnicea (ROCR5800)"
    },
    {
        value: "92901",
        label: "BVF Zona Liberă Brăila (ROGL0710)"
    },
    {
        value: "22902",
        label: "BVF Zona Liberă Curtici (ROTM2300)"
    },
    {
        value: "172901",
        label: "BVF Zona Liberă Galaţi (ROGL3810)"
    },
    {
        value: "522901",
        label: "BVF Zona Liberă Giurgiu (ROBU3980)"
    },
    {
        value: "12801",
        label: "BVI Alba Iulia (ROBV0300)"
    },
    {
        value: "342801",
        label: "BVI Alexandria (ROCR0310)"
    },
    {
        value: "232801",
        label: "BVI Antrepozite / Ilfov (ROBU1200)"
    },
    {
        value: "22801",
        label: "BVI Arad (ROTM0200)"
    },
    {
        value: "42801",
        label: "BVI Bacău (ROIS0600)"
    },
    {
        value: "242801",
        label: "BVI Baia Mare (ROCJ0500)"
    },
    {
        value: "62801",
        label: "BVI Bistriţa - Năsăud (ROCJ0400)"
    },
    {
        value: "72801",
        label: "BVI Botoşani (ROIS1600)"
    },
    {
        value: "82801",
        label: "BVI Braşov (ROBV0900)"
    },
    {
        value: "402801",
        label: "BVI Bucureşti Poştă (ROBU1380)"
    },
    {
        value: "102801",
        label: "BVI Buzău (ROGL1500)"
    },
    {
        value: "512801",
        label: "BVI Călăraşi (ROCT1710)"
    },
    {
        value: "122801",
        label: "BVI Cluj Napoca (ROCJ1800)"
    },
    {
        value: "282801",
        label: "BVI Corabia (ROCR2000)"
    },
    {
        value: "162801",
        label: "BVI Craiova (ROCR2100)"
    },
    {
        value: "202801",
        label: "BVI Deva (ROTM8100)"
    },
    {
        value: "392801",
        label: "BVI Focșani (ROGL3600)"
    },
    {
        value: "522801",
        label: "BVI Giurgiu (ROBU3910)"
    },
    {
        value: "192801",
        label: "BVI Miercurea Ciuc (ROBV5600)"
    },
    {
        value: "282802",
        label: "BVI Olt (ROCR8210)"
    },
    {
        value: "52801",
        label: "BVI Oradea (ROCJ6570)"
    },
    {
        value: "272801",
        label: "BVI Piatra Neamţ (ROIS7400)"
    },
    {
        value: "32801",
        label: "BVI Pitești (ROCR7000)"
    },
    {
        value: "292801",
        label: "BVI Ploiești (ROBU7100)"
    },
    {
        value: "382801",
        label: "BVI Râmnicu Vâlcea (ROCR7700)"
    },
    {
        value: "112801",
        label: "BVI Reșița (ROTM7600)"
    },
    {
        value: "302801",
        label: "BVI Satu - Mare (ROCJ7810)"
    },
    {
        value: "142801",
        label: "BVI Sfântu Gheorghe (ROBV7820)"
    },
    {
        value: "322801",
        label: "BVI Sibiu (ROBV7900)"
    },
    {
        value: "212801",
        label: "BVI Slobozia (ROCT8220)"
    },
    {
        value: "332801",
        label: "BVI Suceava (ROIS8230)"
    },
    {
        value: "152801",
        label: "BVI Târgoviște (ROBU8600)"
    },
    {
        value: "182801",
        label: "BVI Târgu Jiu (ROCR8810)"
    },
    {
        value: "262801",
        label: "BVI Târgu Mureş (ROBV8800)"
    },
    {
        value: "402802",
        label: "BVI Târguri și Expoziții (ROBU1400)"
    },
    {
        value: "352802",
        label: "BVI Timişoara Bază (ROTM8720)"
    },
    {
        value: "372801",
        label: "BVI Vaslui (ROIS9610)"
    },
    {
        value: "312801",
        label: "BVI Zalău (ROCJ9700)"
    }
];
export function getDocumentTypes(translate) {
    return [
        {
            label: translate("etransport-declaration.create-edit.documents-modal.document-type-cmr.label"),
            value: DOCUMENTS_TYPES.CMR_NOTE
        },
        {
            label: translate("etransport-declaration.create-edit.documents-modal.document-type-invoice.label"),
            value: DOCUMENTS_TYPES.INVOICE
        },
        {
            label: translate("etransport-declaration.create-edit.documents-modal.document-type-delivery-note.label"),
            value: DOCUMENTS_TYPES.DELIVERY_NOTE
        },
        {
            label: translate("etransport-declaration.create-edit.documents-modal.document-type-other.label"),
            value: DOCUMENTS_TYPES.OTHER
        }
    ];
}
export function getStartAddressTypes(param) {
    var operationType = param.operationType, translate = param.translate;
    switch(operationType){
        case [
            OPERATION_TYPES.EU_EXPORT,
            OPERATION_TYPES.IOHN_OPERATION_EU_EXPORT,
            OPERATION_TYPES.CALL_OF_STOCK_EXPORT,
            OPERATION_TYPES.NATIONAL_TRANSPORT,
            OPERATION_TYPES.NON_EU_EXPORT,
            OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_EXPORT
        ].find(function(item) {
            return item === operationType;
        }):
            return [
                {
                    label: translate("etransport-declaration.address-type.location.label"),
                    value: ADDRESSES_TYPES.ADDRESS
                }
            ];
        case OPERATION_TYPES.NON_EU_IMPORT:
            return [
                {
                    label: translate("etransport-declaration.address-type.border-crossing-point.label"),
                    value: ADDRESSES_TYPES.BORDER_CROSSING_POINT
                },
                {
                    label: translate("etransport-declaration.address-type.customs-office.label"),
                    value: ADDRESSES_TYPES.CUSTOMS_OFFICE
                },
                {
                    label: translate("etransport-declaration.address-type.location.label"),
                    value: ADDRESSES_TYPES.ADDRESS
                }
            ];
        default:
            return [
                {
                    label: translate("etransport-declaration.address-type.location.label"),
                    value: ADDRESSES_TYPES.ADDRESS
                },
                {
                    label: translate("etransport-declaration.address-type.border-crossing-point.label"),
                    value: ADDRESSES_TYPES.BORDER_CROSSING_POINT
                }
            ];
    }
}
export function getEndAddressTypes(param) {
    var operationType = param.operationType, translate = param.translate;
    switch(operationType){
        case [
            OPERATION_TYPES.IOHN_OPERATION_EU_IMPORT,
            OPERATION_TYPES.CALL_OF_STOCK_IMPORT,
            OPERATION_TYPES.NATIONAL_TRANSPORT,
            OPERATION_TYPES.NON_EU_IMPORT,
            OPERATION_TYPES.EU_CHANGE_TRANSPORT_OR_DEPOSIT_IMPORT
        ].find(function(item) {
            return item === operationType;
        }):
            return [
                {
                    label: translate("etransport-declaration.address-type.location.label"),
                    value: ADDRESSES_TYPES.ADDRESS
                }
            ];
        case OPERATION_TYPES.NON_EU_EXPORT:
            return [
                {
                    label: translate("etransport-declaration.address-type.border-crossing-point.label"),
                    value: ADDRESSES_TYPES.BORDER_CROSSING_POINT
                },
                {
                    label: translate("etransport-declaration.address-type.customs-office.label"),
                    value: ADDRESSES_TYPES.CUSTOMS_OFFICE
                },
                {
                    label: translate("etransport-declaration.address-type.location.label"),
                    value: ADDRESSES_TYPES.ADDRESS
                }
            ];
        default:
            return [
                {
                    label: translate("etransport-declaration.address-type.location.label"),
                    value: ADDRESSES_TYPES.ADDRESS
                },
                {
                    label: translate("etransport-declaration.address-type.border-crossing-point.label"),
                    value: ADDRESSES_TYPES.BORDER_CROSSING_POINT
                }
            ];
    }
}
export function getProductOperationTypes(param) {
    var operationType = param.operationType, translate = param.translate;
    switch(operationType){
        case OPERATION_TYPES.EU_IMPORT:
            return [
                {
                    label: translate("etransport-declaration.product-operation-type-101"),
                    value: PRODUCT_OPERATION_TYPES.TRADING
                },
                {
                    label: translate("etransport-declaration.product-operation-type-201"),
                    value: PRODUCT_OPERATION_TYPES.PRODUCTION
                },
                {
                    label: translate("etransport-declaration.product-operation-type-301"),
                    value: PRODUCT_OPERATION_TYPES.FREE
                },
                {
                    label: translate("etransport-declaration.product-operation-type-401"),
                    value: PRODUCT_OPERATION_TYPES.COMMERCIAL_EQUIPMENT
                },
                {
                    label: translate("etransport-declaration.product-operation-type-501"),
                    value: PRODUCT_OPERATION_TYPES.ASSET
                },
                {
                    label: translate("etransport-declaration.product-operation-type-601"),
                    value: PRODUCT_OPERATION_TYPES.OWN_USAGE
                },
                {
                    label: translate("etransport-declaration.product-operation-type-703"),
                    value: PRODUCT_OPERATION_TYPES.DELIVERY_AND_INSTALL
                },
                {
                    label: translate("etransport-declaration.product-operation-type-801"),
                    value: PRODUCT_OPERATION_TYPES.LEASING
                },
                {
                    label: translate("etransport-declaration.product-operation-type-802"),
                    value: PRODUCT_OPERATION_TYPES.WARRANTY_GOODS
                },
                {
                    label: translate("etransport-declaration.product-operation-type-901"),
                    value: PRODUCT_OPERATION_TYPES.EXEMPTED_OPERATION
                },
                {
                    label: translate("etransport-declaration.product-operation-type-1001"),
                    value: PRODUCT_OPERATION_TYPES.INVESTMENT_IN_PROGRESS
                },
                {
                    label: translate("etransport-declaration.product-operation-type-1101"),
                    value: PRODUCT_OPERATION_TYPES.DONATION
                },
                {
                    label: translate("etransport-declaration.product-operation-type-9901"),
                    value: PRODUCT_OPERATION_TYPES.OTHER
                }
            ];
        case OPERATION_TYPES.EU_EXPORT:
            return [
                {
                    label: translate("etransport-declaration.product-operation-type-101"),
                    value: PRODUCT_OPERATION_TYPES.TRADING
                },
                {
                    label: translate("etransport-declaration.product-operation-type-301"),
                    value: PRODUCT_OPERATION_TYPES.FREE
                },
                {
                    label: translate("etransport-declaration.product-operation-type-703"),
                    value: PRODUCT_OPERATION_TYPES.DELIVERY_AND_INSTALL
                },
                {
                    label: translate("etransport-declaration.product-operation-type-801"),
                    value: PRODUCT_OPERATION_TYPES.LEASING
                },
                {
                    label: translate("etransport-declaration.product-operation-type-802"),
                    value: PRODUCT_OPERATION_TYPES.WARRANTY_GOODS
                },
                {
                    label: translate("etransport-declaration.product-operation-type-9901"),
                    value: PRODUCT_OPERATION_TYPES.OTHER
                }
            ];
        case OPERATION_TYPES.NATIONAL_TRANSPORT:
            return [
                {
                    label: translate("etransport-declaration.product-operation-type-101"),
                    value: PRODUCT_OPERATION_TYPES.TRADING
                },
                {
                    label: translate("etransport-declaration.product-operation-type-704"),
                    value: PRODUCT_OPERATION_TYPES.TRANSFER_WAREHOUSE
                },
                {
                    label: translate("etransport-declaration.product-operation-type-705"),
                    value: PRODUCT_OPERATION_TYPES.CLIENT_GOODS
                },
                {
                    label: translate("etransport-declaration.product-operation-type-9901"),
                    value: PRODUCT_OPERATION_TYPES.OTHER
                }
            ];
        default:
            return [
                {
                    label: translate("etransport-declaration.product-operation-type-9999"),
                    value: PRODUCT_OPERATION_TYPES.SAME_WITH_DECLARATION_OPERATION
                }
            ];
    }
}
export var formikFieldsWithoutUI = [
    "netWeightTotal",
    "grossWeightTotal",
    "priceInCurrency",
    "netTotal"
];
// eslint-disable-next-line
export function onDownloadDeclarationPdf(_) {
    return _onDownloadDeclarationPdf.apply(this, arguments);
}
function _onDownloadDeclarationPdf() {
    _onDownloadDeclarationPdf = _async_to_generator(function(param) {
        var pdfFileName, dispatch, myCompanyId, declarationId, setIsLoading, abortControllerSignal, translate, setSuccessMessage, setErrorMessage, pdf, pdfBlob, blob, e, error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    pdfFileName = param.pdfFileName, dispatch = param.dispatch, myCompanyId = param.myCompanyId, declarationId = param.declarationId, setIsLoading = param.setIsLoading, abortControllerSignal = param.abortControllerSignal, translate = param.translate, setSuccessMessage = param.setSuccessMessage, setErrorMessage = param.setErrorMessage;
                    setIsLoading(true);
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        3,
                        4,
                        5
                    ]);
                    return [
                        4,
                        dispatch(etransportActions.downloadDeclarationPdf({
                            myCompanyId: myCompanyId,
                            declarationId: declarationId
                        }, abortControllerSignal))
                    ];
                case 2:
                    pdf = _state.sent();
                    pdfBlob = base64ToArrayBuffer(pdf);
                    blob = new Blob([
                        pdfBlob
                    ], {
                        type: "application/pdf;base64"
                    });
                    FileSaver.saveAs(blob, "".concat(pdfFileName, ".pdf"));
                    setSuccessMessage({
                        message: translate("etransport-declaration.download-pdf.success-message")
                    });
                    return [
                        3,
                        5
                    ];
                case 3:
                    e = _state.sent();
                    error = getErrorMessageTranslated({
                        errors: mapErrorByHttpStatusCode(e),
                        translate: translate
                    });
                    setErrorMessage({
                        message: error
                    });
                    return [
                        3,
                        5
                    ];
                case 4:
                    setIsLoading(false);
                    return [
                        7
                    ];
                case 5:
                    return [
                        2
                    ];
            }
        });
    });
    return _onDownloadDeclarationPdf.apply(this, arguments);
}
export function preSubmitDeclaration(param) {
    var values = param.values;
    var currencyRate = values.currencyRate, myCompanyDeliveryAddressId = values.myCompanyDeliveryAddressId, partnerDeliveryAddressId = values.partnerDeliveryAddressId, startAddressBorderCrossingPoint = values.startAddressBorderCrossingPoint, startAddressCustomsOffice = values.startAddressCustomsOffice, endAddressBorderCrossingPoint = values.endAddressBorderCrossingPoint, endAddressCustomsOffice = values.endAddressCustomsOffice, products = values.products, newValues = _object_without_properties(values, [
        "currencyRate",
        "myCompanyDeliveryAddressId",
        "partnerDeliveryAddressId",
        "startAddressBorderCrossingPoint",
        "startAddressCustomsOffice",
        "endAddressBorderCrossingPoint",
        "endAddressCustomsOffice",
        "products"
    ]);
    if (values.currencyCode !== DEFAULT_CURRENCY_CODE) {
        newValues.currencyRate = currencyRate;
    }
    if (myCompanyDeliveryAddressId) {
        newValues.myCompanyDeliveryAddressId = myCompanyDeliveryAddressId;
    }
    if (partnerDeliveryAddressId) {
        newValues.partnerDeliveryAddressId = partnerDeliveryAddressId;
    }
    if (startAddressBorderCrossingPoint) {
        newValues.startAddressBorderCrossingPoint = startAddressBorderCrossingPoint;
    }
    if (startAddressCustomsOffice) {
        newValues.startAddressCustomsOffice = startAddressCustomsOffice;
    }
    if (endAddressBorderCrossingPoint) {
        newValues.endAddressBorderCrossingPoint = endAddressBorderCrossingPoint;
    }
    if (endAddressCustomsOffice) {
        newValues.endAddressCustomsOffice = endAddressCustomsOffice;
    }
    newValues.products = products.map(function(item) {
        var processedProduct = {
            productName: item.productName,
            operationType: item.operationType,
            productPackagingId: item.productPackagingId || null,
            referenceCode: item.referenceCode,
            quantity: item.quantity,
            measurementUnitCode: item.measurementUnitCode,
            price: item.price,
            productEuCnClassificationCode: item.productEuCnClassificationCode,
            netWeight: item.netWeight,
            grossWeight: item.grossWeight,
            netTotal: item.price ? item.netTotal : "",
            netWeightTotal: item.netWeight ? item.netWeightTotal : "",
            grossWeightTotal: item.grossWeightTotal
        };
        if (values.currencyCode !== DEFAULT_CURRENCY_CODE) {
            processedProduct.priceInCurrency = item.price;
            processedProduct.price = item.priceInCurrency;
        }
        return processedProduct;
    });
    return newValues;
}
