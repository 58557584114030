function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { stringify } from "qs";
import { configSelectors } from "./config";
function requestAction(options) {
    return function(dispatch, getState, param) {
        var httpClient = param.httpClient;
        var host = configSelectors.apiHost(getState());
        var version = configSelectors.version(getState());
        if (options.method.toLowerCase() === "get" && options.data && Object.keys(options.data).length > 0) {
            options.url += "?" + stringify(options.data);
            delete options.data;
        }
        options = _object_spread_props(_object_spread({}, options), {
            url: "".concat(host).concat(options.url),
            headers: _object_spread({
                "Content-Type": "application/json",
                "X-Version": version
            }, options.headers)
        });
        return httpClient.request(options).then(function(response) {
            if (options.rawResponse) {
                return response;
            }
            return response.data;
        });
    };
}
export function callPublicApiAction(method, url) {
    var data = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, options = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};
    return requestAction(_object_spread({
        url: url,
        method: method,
        data: data
    }, options));
}
export function callPrivateApiAction(method, url) {
    var data = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, options = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};
    return function(dispatch) {
        return dispatch(requestAction(_object_spread_props(_object_spread({
            url: url,
            method: method,
            data: data
        }, options), {
            headers: _object_spread({}, options.headers)
        })));
    };
}
